import _ from 'lodash-es';
import { isEmptyString } from './isEmptyString';

export const defaultViewData = '-';

export function filterDefaultViewData(data, defaultValue = defaultViewData) {
  const newData = _.cloneDeep(data);
  _.each(newData, function (value, key) {
    if (_.isUndefined(value) || _.isNull(value) || isEmptyString(value)) {
      newData[key] = defaultValue;
    }
  });

  return newData;
}
