<template>
  <div class="date-filter-panel">
    <popup-header>统计周期</popup-header>
    <div class="main">
      <slot name="extend"></slot>
      <div class="quick-panel">
        <div class="quick-button-wrap">
          <div
            v-for="(item, index) in configState.enable"
            :class="['quick-button-item', { last: index === enableLength - 1 && item.length < lineNumber }]"
            :key="index">
            <span
              v-for="col in item"
              :key="col"
              :class="['quick-button', { on: col === formData.quickType }]"
              @click="handleQuickButton(col)">
              {{ quickText[col] }}
            </span>
          </div>
        </div>
      </div>
      <div class="date-range">
        <div class="date-header">
          <h3>自定义时间</h3>
        </div>
        <div
          class="current-date-range"
          @click="handleOpenCalendar">
          <span>{{ currentDateRange }}</span>
        </div>
      </div>
      <div class="footer">
        <van-button
          @click="handleReset"
          block>
          重置
        </van-button>
        <van-button
          class="btn-submit"
          type="primary"
          block
          @click="handleSubmit">
          确认
        </van-button>
      </div>
    </div>

    <van-calendar
      v-model:show="showCalendar"
      title="选择时间"
      type="range"
      color="#22B894"
      :round="false"
      :min-date="minDate"
      allow-same-day
      :default-date="currentDateRangeOrigin"
      @confirm="handleConfirmDateRange" />
  </div>
</template>

<script setup>
  import { computed, ref, watch } from 'vue';
  import _ from 'lodash-es';
  import { formatString, getDateRangeByType, QUICK_KEY, QUICK_TEXT } from '@/components/dateFilterPanel/utils.js';
  import dayjs from 'dayjs';
  import PopupHeader from '@/components/PopupHeader.vue';
  import { feeUtils } from '@/utils/feeUtils';

  const props = defineProps({
    config: {
      type: Object,
      default: () => {},
    },
    source: {
      type: String,
      default: '',
    },
  });
  const emit = defineEmits(['submit', 'reset']);

  const customTimeRang = ref([]);

  const quickText = QUICK_TEXT;
  const defaultQuickType = QUICK_KEY.yesterday;
  const lineNumber = 4;

  const defaultConfig = {
    // 快捷类型
    quickType: defaultQuickType,
    // 哪些需要展示
    enable: _.chunk(
      [
        QUICK_KEY.today,
        // QUICK_KEY.yesterday,
        QUICK_KEY.currentWeek,
        QUICK_KEY.currentMonth,
        QUICK_KEY.currentYear,
        QUICK_KEY.prevWeek,
        QUICK_KEY.prevMonth,
      ],
      lineNumber,
    ),
  };

  const initialConfig = mergeConfig(props.config);

  const prevYear = dayjs().subtract(1, 'year').toDate();

  const configState = ref(initialConfig);
  const formData = ref({
    quickType: configState.value.quickType,
    dateRange:
      configState.value.quickType !== QUICK_KEY.custom
        ? getDateRangeByType(configState.value.quickType)
        : localStorage.getItem('customData').split(','),
  });
  const showCalendar = ref(false);
  const minDate = ref(prevYear);

  watch(
    () => props.config,
    (newValue) => {
      configState.value = mergeConfig(newValue ?? {});
      if (configState.value.quickType === QUICK_KEY.custom) {
        formData.value.dateRange = customTimeRang.value;
        formData.value.quickType = configState.value.quickType;
      } else {
        syncFormData(configState.value.quickType);
      }
    },
    {
      deep: true,
    },
  );

  const currentDateRange = computed(() => {
    const dateRange = formData.value.dateRange;
    console.log('dateRange===', dateRange, formData);
    if (props.source === 'charging' && (formData.value.quickType === QUICK_KEY.currentMonth || formData.value.quickType === QUICK_KEY.prevMonth)) {
      const currentDate = feeUtils
        .getApplyCycleByYM(dayjs(dateRange[0]).format('YYYY-MM-DD'))
        .map((item) => (dayjs(item).valueOf() > dayjs().valueOf() ? dayjs().format('YYYY-MM-DD') : dayjs(item).format('YYYY-MM-DD')));
      return `${currentDate[0]} ~ ${currentDate[1]}`;
    }
    if (dateRange.length <= 0) {
      return '';
    }

    return `${dateRange[0]} ~ ${dateRange[1]}`;
  });

  const currentDateRangeOrigin = computed(() => {
    const dateRange = formData.value.dateRange;

    if (dateRange.length <= 0) {
      return [];
    }

    return [new Date(dateRange[0]), new Date(dateRange[1])];
  });

  const enableLength = computed(() => configState.value.enable.length);

  function handleOpenCalendar() {
    showCalendar.value = true;
  }

  function handleSubmit() {
    customTimeRang.value = formData.value.dateRange;
    if (formData.value.quickType === QUICK_KEY.custom) {
      localStorage.setItem('customData', formData.value.dateRange);
    }
    emit('submit', formData.value);
  }

  function handleQuickButton(quickType) {
    syncFormData(quickType);
  }

  function handleReset() {
    formData.value.quickType = defaultQuickType;
    formData.value.dateRange = getDateRangeByType(defaultQuickType);

    emit('reset', formData.value);
  }

  function handleConfirmDateRange(values) {
    console.log('sss=s=s=s=ssss====,', values);
    showCalendar.value = false;
    if (Array.isArray(values)) {
      const [start, end] = values ?? [];
      const startDate = dayjs(start).format(formatString);
      const endDate = dayjs(end).format(formatString);
      formData.value.dateRange = [startDate, endDate];
      formData.value.quickType = QUICK_KEY.custom;
    } else {
      const startDate = dayjs(values).format(formatString);
      const endDate = dayjs(values).format(formatString);
      formData.value.dateRange = [startDate, endDate];
      formData.value.quickType = QUICK_KEY.custom;
    }
  }

  function syncFormData(quickType) {
    const defaultDateRange = getDateRangeByType(defaultQuickType);

    formData.value.quickType = quickType;
    formData.value.dateRange = getDateRangeByType(quickType, {
      dateRange: defaultDateRange,
    });
  }

  function mergeConfig(newConfig = {}) {
    const result = {
      ...defaultConfig,
      ...newConfig,
    };
    const enable = newConfig.enable ?? defaultConfig.enable;

    return {
      ...result,
      enable,
    };
  }
</script>

<style scoped lang="scss">
  $quickSpace: 12px;

  .quick-button-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: $quickSpace;
    margin-left: -$quickSpace;
    &.last {
      justify-content: flex-start;
    }
  }

  .date-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .time-header-bg {
    width: 20px;
    height: 20px;
  }

  .quick-button {
    width: 77px;
    margin-left: $quickSpace;
    box-sizing: border-box;
    padding: 6px 0;
    text-align: center;
    font-family: 'PingFang SC';
    font-size: 14px;
    line-height: 20px;
    border: 1px solid #e5e6eb;
    border-radius: 4px;
    transition: all 0.3s;
    &.on {
      color: #22b894;
      background: #ebf7f3;
      border-color: #ebf7f3;
      transition: all 0.3s;
    }
  }

  .main {
    padding: 16px 15.5px 24px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
  }

  .btn-submit {
    margin-left: 12px;
  }

  .current-date-range {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 32px;
    margin-bottom: 16px;
    border: 1px solid #e5e6eb;
    border-radius: 4px;
    font-family: 'PingFang SC';
    font-size: 14px;
    line-height: 20px;
    color: #1d2129;
  }

  .quick-panel-title {
    margin: 0 0 12px;
  }
</style>
