import _, { forIn } from 'lodash-es';

export const typeDistributionBar = (data) => {
  const y_data_1 = [];
  const y_data_2 = [];
  const x_data = [];
  forIn(data, (value, key) => {
    console.log(key,value);
    x_data.push(value.businessName);
    y_data_1.push(value.newSignNo);
    y_data_2.push(value.renewSignNo);
  })
  console.log(x_data);
  console.log(y_data_1);    
  console.log(y_data_2);
  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      }
    },
    color: ['#063050', '#FFEB3B', '#9C27B0', '#E91E63'],
    legend: {
      data: ['新增', '续签']
    },
    xAxis: [
      {
        type: 'category',
        data: x_data,
        axisPointer: {
          type: 'shadow'
        },
        axisLabel: { interval: 0, rotate: 45 }
      }
    ],
    yAxis: [
      {
        type: 'value',
        min: 0,
        axisLabel: {
          formatter: '{value}'
        }
      }
    ],
    series: [
      {
        name: '新增',
        type: 'bar',
        // tooltip: {
        //   valueFormatter: function (value) {
        //     return value;
        //   }
        // },
        data: y_data_1
      },
      {
        name: '续签',
        type: 'bar',
        // tooltip: {
        //   valueFormatter: function (value) {
        //     return value;
        //   }
        // },
        data: y_data_2
      }
    ]
  }
};
