import dayjs from 'dayjs';
import { prevMonth, prevWeek, yesterday } from '@/components/dateFilterPanel/utils';

export const formatString = 'YYYY-MM-DD';

export const formatFullString = 'YYYY-MM-DD hh:mm:ss';

export const QUICK_KEY = {
  today: 'today',
  yesterday: 'yesterday',
  currentWeek: 'currentWeek',
  currentMonth: 'currentMonth',
  currentYear: 'currentYear',
  prevWeek: 'prevWeek',
  prevMonth: 'prevMonth',
  custom: 'custom',
};

export const DateTypeEnum = {
  today: 'TO_DAY',
  thisWeek: 'THIS_WEEK',
  thisMonth: 'THIS_MONTH',
  thisYear: 'THIS_YEAR',
  lastMonth: 'LAST_MONTH',
  custom: 'CUSTOM',
};

export const DateTypeMap = {
  [DateTypeEnum.today]: QUICK_KEY.today,
  [DateTypeEnum.thisWeek]: QUICK_KEY.currentWeek,
  [DateTypeEnum.thisMonth]: QUICK_KEY.currentMonth,
  [DateTypeEnum.thisYear]: QUICK_KEY.currentYear,
  [DateTypeEnum.lastMonth]: QUICK_KEY.prevMonth,
  [DateTypeEnum.custom]: QUICK_KEY.custom,
};

export const DateTypeV1Map = {
  [QUICK_KEY.today]: DateTypeEnum.today,
  [QUICK_KEY.yesterday]: DateTypeEnum.custom,
  [QUICK_KEY.currentWeek]: DateTypeEnum.thisWeek,
  [QUICK_KEY.currentMonth]: DateTypeEnum.thisMonth,
  [QUICK_KEY.currentYear]: DateTypeEnum.thisYear,
  [QUICK_KEY.prevWeek]: DateTypeEnum.custom,
  [QUICK_KEY.prevMonth]: DateTypeEnum.lastMonth,
  [QUICK_KEY.custom]: DateTypeEnum.custom,
};

export const DateDimensionEnum = {
  year: 'year',
  month: 'month',
  day: 'day',
  // 车队
  fleet: 'fleet',
  // 纵队
  fleetGroup: 'fleetGroup',
};

export const DateTypeTextEnum = {
  [DateTypeEnum.today]: '今日',
  [DateTypeEnum.thisWeek]: '本周',
  [DateTypeEnum.thisMonth]: '本月',
  [DateTypeEnum.thisYear]: '本年',
  [DateTypeEnum.lastMonth]: '上月',
  [DateTypeEnum.custom]: '自定义',
};

export function today() {
  const start = dayjs().format(formatString);

  return [start, start];
}

export function last7days() {
  const end = dayjs().format(formatString);
  const start = dayjs().subtract(6, 'day').format(formatString);
  return [start, end];
}

export function lastMonth() {
  function getPrevMonth() {
    return dayjs().subtract(1, 'month');
  }

  const start = getPrevMonth().startOf('month').format(formatString);
  const end = getPrevMonth().endOf('month').format(formatString);
  return [start, end];
}

export function currentWeek() {
  const start = dayjs()
    .subtract(dayjs().day() ? dayjs().day() - 1 : 6, 'day')
    .format(formatString);
  const end = dayjs().format(formatString);

  return [start, end];
}

export function currentMonth() {
  const start = dayjs().startOf('month').format(formatString);
  const end = dayjs().format(formatString);
  return [start, end];
}

export function currentYear() {
  const start = dayjs().startOf('year').format(formatString);
  const end = dayjs().format(formatString);
  return [start, end];
}

export function getDateRangeByType(type) {
  if (type === DateTypeEnum.today) {
    return today();
  } else if (type === DateTypeEnum.thisWeek) {
    return currentWeek();
  } else if (type === DateTypeEnum.thisMonth) {
    return currentMonth();
  } else if (type === DateTypeEnum.lastMonth) {
    return lastMonth();
  } else if (type === DateTypeEnum.thisYear) {
    return currentYear();
  }
}

export function getDateRangeByTypeV1(type, customRange = []) {
  if (type === QUICK_KEY.today) {
    return today();
  } else if (type === QUICK_KEY.yesterday) {
    return yesterday();
  } else if (type === QUICK_KEY.currentWeek) {
    return currentWeek();
  } else if (type === QUICK_KEY.currentMonth) {
    return currentMonth();
  } else if (type === QUICK_KEY.currentYear) {
    return currentYear();
  } else if (type === QUICK_KEY.prevWeek) {
    return prevWeek();
  } else if (type === QUICK_KEY.prevMonth) {
    return prevMonth();
  } else {
    return customRange;
  }
}
