import { color } from 'echarts';
import _, { forIn } from 'lodash-es';

export const studentNumRunOptions = (data) => {
  const _data = [];
  forIn(data, (value, key) => {
    if(key === 'aLLBoyNo') {
      _data.push({
        value: value,
        name: '全托-男'
      })
    } else if(key === 'allGirlNo') {
      _data.push({
        value: value,
        name: '全托-女'
      })
    } else if(key === 'noonBoyNo') {
      _data.push({
        value: value,
        name: '午托-男'
      })
    } else if(key === 'noonGirlNo') {
      _data.push({
        value: value,
        name: '午托-女'
      })
    }
    
  })

  return {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      top: '5%',
      left: 'center'
    },
    color: ['#063050', '#73C0DE', '#FAC858', '#17435E'],
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: _data
      }
    ]
  }
};
