<template>
  <div class="page-container">
   <div class="org-content">
      <van-dropdown-menu>
        <van-dropdown-item @change="handleOrgChange" v-model="state.currentOrg" :options="state.orgList" />
      </van-dropdown-menu>
    </div>

    <div class="report-content">
        <div class="total-money">
            <div class="row-column">
                <span>总签约金额</span>
                <span style="color: #f5a623;margin-top: 6px;font-size: 36px">¥{{state.staticData.totalAmount}}</span>
            </div>
        </div>
        <div class="second-money">
            <div class="row-column">
                <span>服务学生</span>
                <span style="color: #91cc75;margin-top: 6px">{{state.staticData.totalStudent}}</span>
            </div>
            <div class="row-column">
                <span>退款金额</span>
                <span style="color: #113050;margin-top: 6px">¥{{state.staticData.refundAmount}}</span>
            </div>
        </div>
    </div>

    <div>
         <div class="header-top">
          <span>数据统计周期：{{ QUICK_TEXT[quickType] }}</span>
          <div
            class="setting-img"
            @click="handleChoiceTimeClick"></div>
        </div>
    </div>

    <div class="report-list">
        <div class="report-item" v-if="quickType == QUICK_KEY.today">
            <div class="row-column">
                <span>书院学生</span>
                <span style="color: #113050;margin-top: 6px;font-size: 18px">{{state.staticData.manageData?.studentNo ?? 0}}</span>
            </div>
        </div>
        <div class="report-item" v-if="quickType == QUICK_KEY.today">
            <div class="row-column">
                <span>10日内到期</span>
                <span style="color: #113050;margin-top: 6px;font-size: 18px">{{state.staticData.manageData?.dueNo || 0}}</span>
            </div>
        </div>
        <div class="report-item" v-if="quickType == QUICK_KEY.today">
            <div class="row-column">
                <span>入住人数</span>
                <span style="color: #113050;margin-top: 6px;font-size: 18px">{{state.staticData.manageData?.checkInNo || 0}}</span>
            </div>
        </div>
        <div class="report-item" v-if="quickType == QUICK_KEY.today">
            <div class="row-column">
                <span>床位使用率</span>
                <span style="color: #113050;margin-top: 6px;font-size: 18px">{{(state.staticData.manageData?.bedRate || 0) * 100}}%</span>
            </div>
        </div>
        <div class="report-item">
            <div class="row-column">
                <span>新增签约单</span>
                <span style="color: #113050;margin-top: 6px;font-size: 18px">{{state.staticData.manageData?.newSignNo || 0}}</span>
            </div>
        </div>
        <div class="report-item">
            <div class="row-column">
                <span>签约金额</span>
                <span style="color: #113050;margin-top: 6px;font-size: 18px">{{state.staticData.manageData?.newSignAmount || 0}}</span>
            </div>
        </div>
        <div class="report-item" v-if="quickType != QUICK_KEY.today">
            <div class="row-column">
                <span>到期未续</span>
                <span style="color: #113050;margin-top: 6px;font-size: 18px">{{state.staticData.manageData?.dueNo || 0}}</span>
            </div>
        </div>
    </div>

    <div class="student-chart" v-if="quickType == QUICK_KEY.today">
       <div class="report-title">在院学生</div>
        <StudentCharts ref="studentChart"  v-if="state.staticData.studentCountByProduct && state.staticData.studentCountByProduct.length > 0" :data="state.staticData.studentCountByProduct" />
        <EmptyData style="margin-top: 100px" v-else/>
    </div>

    <div class="report-table" v-if="quickType == QUICK_KEY.today">
      <div class="report-title">导师学生</div>
      <SortPaginationTable
        style="margin-top: 10px"
        :data="state.tableList"
        :columns="columns"
      />
    </div>

    <div class="report-table">
      <div class="report-title">{{quickType == QUICK_KEY.today ? '10日内到期' : '到期未续约'}}</div>
      <SortPaginationTable
        style="margin-top: 10px"
        :data="state.expireTableList"
        :columns="expireColumns"
      />
    </div>

    <div class="student-chart" v-if="quickType == QUICK_KEY.today">
       <div class="report-title">入住人数</div>
        <StudentNumCharts ref="studentNumChart"  v-if="state.staticData.bedData" :data="state.staticData.bedData" />
        <EmptyData style="margin-top: 100px" v-else/>
    </div>

    <div class="student-chart" v-if="quickType == QUICK_KEY.today">
       <div class="report-title">床位使用率</div>
        <BedRateRunCharts ref="studentNumChart"  v-if="state.staticData.bedData" :data="state.staticData.bedData" />
        <EmptyData style="margin-top: 100px" v-else/>
    </div>

    <div class="report-table">
      <div class="report-title">新增签约</div>
      <SortPaginationTable
        style="margin-top: 10px"
        :data="state.staticData.newSignData"
        :columns="addColumns"
      />
    </div>

    <div class="student-chart-container" v-if="quickType != QUICK_KEY.today">
       <div class="report-title">类型分布</div>
        <div style="height: 300px;">
          <TypeDistributionBar ref="typeDistributionBarChart"  v-if="state.staticData.studentCountByProduct && state.staticData.studentCountByProduct.length > 0" :data="state.staticData.studentCountByProduct" />
          <EmptyData v-else/>
        </div>

         <div style="margin-top: 16px;height: 300px;">
          <TypeDistributionPie ref="typeDistributionPieChart"  v-if="state.staticData.newRenewData && !(state.staticData.newRenewData.newSignNo ==0 && state.staticData.newRenewData.newSignNo == 0)" :data="state.staticData.newRenewData" />
          <EmptyData v-else/>
        </div>
    </div>


    <van-popup
      v-model:show="isShowPopup"
      closeable
      position="bottom"
      @click-overlay="handleClose"
      @click-close-icon="handleClose">
      <date-filter-panel
        @submit="handleChoiceTime"
        :config="{ quickType: quickType }"></date-filter-panel>
    </van-popup>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { queryBusinessUserData, querySelectOrgListByUser } from '@/api/user'
import { useRoute } from "vue-router";
import EmptyData from '@/components/EmptyData.vue';
import DateFilterPanel from '@/components/dateFilterPanel/DateFilterPanel.vue';
import { getDateRangeByType, QUICK_KEY, QUICK_TEXT } from '@/components/dateFilterPanel/utils.js';
import StudentCharts from '@/components/StudentCharts.vue'
import SortPaginationTable from "@/components/business/SortPaginationTable.vue";
import dayjs from "dayjs";
import StudentNumCharts from '@/components/StudentNumCharts.vue'
import BedRateRunCharts from '@/components/BedRateRunCharts.vue'
import TypeDistributionPie from '@/components/TypeDistributionPie.vue'
import TypeDistributionBar from '@/components/TypeDistributionBar.vue'
import { forIn } from "lodash-es";
const columns = [
  {
    title: "导师",
    dataIndex: "staffName",
    width: 50,
    start: true
  },
  {
    title: "当前学生",
    dataIndex: "stuNo",
    width: 40,
  },
  {
    title: "签约产品",
    dataIndex: "productCountStr",
    isPup: true,
    width: 180,
  },
  {
    title: "签约金额",
    dataIndex: "signAmount",
    width: 80,
  },
];

const expireColumns = [
  {
    title: "导师",
    dataIndex: "staffName",
    width: 50,
    start: true,
  },
  {
    title: "学生",
    dataIndex: "stuName",
    width: 60,
  },
  {
    title: "签约产品",
    dataIndex: "businessNameDate",
    isPup: true,
  }
];


const addColumns = [
  {
    title: "导师",
    dataIndex: "staffName",
    width: 50,
    start: true,
  },
  {
    title: "学生",
    dataIndex: "stuName",
    width: 60,
  },
  {
    title: "签约产品",
    dataIndex: "businessName",
    isPup: true,
  },
  {
    title: "类型",
    dataIndex: "signTypeDesc",
  },
  {
    title: "签约金额",
    dataIndex: "signMoney",
  }
];

export default defineComponent({
  name: "ManagementPanel",

  components: {
    EmptyData,
    DateFilterPanel,
    StudentCharts,
    SortPaginationTable,
    StudentNumCharts,
    BedRateRunCharts,
    TypeDistributionBar,
    TypeDistributionPie
  },

  setup() {
    const defaultQuickType = QUICK_KEY.today;
    const defaultQuery = getDateRangeByType(defaultQuickType);

    console.log('defaultQuery>>>',defaultQuery)
    const quickType = ref(defaultQuickType);

    const route = useRoute();
    const state = reactive({
      loading: false,
      isFinsh: false,
      currentOrg: null,
      orgList: [],
      totalMoney: 0,
      staticData: {
        totalAmount: 0,
        refundAmount: 0,
        totalStudent: 0,
        manageData: {
          bedRate: 0,
          checkInNo: 0,
          dueNo: 0,
          newSignNo: 0,
          studentNo: 0,
          newSignAmount: 0
        },
        studentCountByProduct: [],
        bedData: []
      },
      tableList:[],
      expireTableList:[],
      params: {
        endTime: defaultQuery[1],
        startTime: defaultQuery[0]
      }
    })

    const isShowPopup = ref(false);
    onMounted(() => {
      if(route?.query?.token) {
        localStorage.setItem('token', route?.query?.token)
      }
      
      querySysOrgListByUser();
      // getBusinessUserData()
      
    });

    const querySysOrgListByUser = async () => {
      state.orgList = [];
      try {
        const userInfo = JSON.parse(localStorage.getItem('userInfo') ?? '{}');
        const result = await querySelectOrgListByUser({
          id: userInfo?.id,
        });
        console.log("result:", result);
        if (result.code === "00") {
          state.orgList = result.data.map(item => {
            return {
              ...item,
              text: item.name,
              value: item.id
            }
          }) ?? [];

          if(route?.query?.currentOrg) {
            state.currentOrg = Number(route?.query?.currentOrg)
          } else {
            state.currentOrg = state.orgList[0].value
          }

          

          getBusinessUserData()
          
        }
      } catch (error) {
        state.orgList = []
      }
    }


    const getBusinessUserData = async () => {
      try {
        state.loading = true;
        const params = {
          ...state.params,
          createOrg: state.currentOrg,
        }
        if(quickType.value === QUICK_KEY.today) {
          delete params.endTime
        }
        const result = await queryBusinessUserData({
          ...params
        });
        if (result.code === "00") {
          
          state.staticData = result.data;
          state.expireTableList = result.data.dueData.map(item => {
            return {
              ...item,
              businessNameDate: `${item.businessName}(${dayjs(item.startTime).format('YYYY-MM-DD')}-${dayjs(item.endTime).format('YYYY-MM-DD')})`
            }
          })
          state.tableList = result.data.staffData.map(item => {
            const str = item.productCount.map(h => `${h.businessName}:${h.stuNo}`)
            return {
              ...item,
              productCountStr: str.join('、')
            }
          })
          state.loading = false;
        } else {
        }
      } catch (error) {
        console.log("error:>>>", error);
      }
    }

    const handleOrgChange = (value) => {
      state.currentOrg = value
      getBusinessUserData();
    }

    const handleChoiceTimeClick = () => {
        isShowPopup.value = true;
    };

    const handleChoiceTime = async (ops) => {
        const [beginAt, endAt] = ops.dateRange;
        quickType.value = ops.quickType;
        state.params.startTime = beginAt
        state.params.endTime = endAt
        isShowPopup.value = false;
        getBusinessUserData()

    };

      const handleClose = () => {
        isShowPopup.value = false;
      };

    return {
      state,
      handleOrgChange,
      handleChoiceTimeClick,
      handleChoiceTime,
      handleClose,
      QUICK_TEXT,
      quickType,
      QUICK_KEY,
      isShowPopup,
      columns,
      expireColumns,
      addColumns

    };
  },
});
</script>

<style lang="scss" scoped>
.page-container {
  background: #e9f1f8;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  margin-bottom: 24px;
}

.org-content {
  margin: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

:deep(.van-dropdown-menu__bar) {
  background: none !important;
  box-shadow: none !important;
}

.report-content {
  margin: 12px 6px;
  padding-top: 14px;
  width: calc(100% - 12px);
  background-color: #fff;
  border-radius: 7px;
  height: 154px;
}

.row-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.total-money {

}

.second-money {
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    align-items: center;
    margin-top: 12px;
}

.report-list {
    margin: 12px 6px;
    // padding-top: 14px;
    width: calc(100% - 12px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.report-item {
    width: 43%;
    background-color: #fff;
    border-radius: 7px;
    margin-top: 6px;
    padding: 12px;
}

.header-top {
      background: #ffffff;
      margin-top: 1px;
      display: flex;
      justify-content: space-between;
      height: 40px;
      color: #86909c;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      align-items: center;
      padding-left: 12px;
      padding-right: 12px;
    }

    .setting-img {
      height: 18px;
      width: 18px;
      background-repeat: no-repeat;
      background-image: url(~@/assets/setting.png);
      background-size: contain;
    }

    .student-chart {
      margin: 12px 10px;
      width: calc(100% - 20px);
      height: 340px;
      background-color: #fff;
      border-radius: 7px;
    }

     .student-chart-container {
      margin: 12px 10px;
      width: calc(100% - 20px);
      height: 680px;
      background-color: #fff;
      border-radius: 7px;
    }

    .report-title {
    font-family: PingFang SC;
    font-weight: 600;
    font-size: 17px;
    color: #000000;
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
  }

  .report-table {
    width: calc(100% - 20px);
    background-color: #fff;
    margin: 0 12px;
    border-radius: 7px;
    margin-top: 12px;
  }

</style>