<template>
  <div
    id="comRunChart"
    ref="comRunChart"></div>
</template>

<script>
  import { defineComponent, onMounted, watch, nextTick, ref } from 'vue';
  import * as echarts from 'echarts';
  import { bedRateRunOptions } from '@/config/bedRateRunOptions';
  import _ from 'lodash-es';

  export default defineComponent({
    name: 'BedRateRunCharts',
    props: {
      data: {
        type: Object,
        default: () => {},
      }
    },
    components: {},

    setup(props) {
      let chart = null;

      const comRunChart = ref(null);

      watch(
        () => props.data,
        (newVal, oldal) => {
          if (!_.isEqual(newVal, oldal)) {
            nextTick(() => {
             console.log('props：',props)
              setOptionData(newVal);
            });
          }
        },
        {
          deep: true,
        },
      );

      onMounted(() => {
        nextTick(() => {
          initChart();
        });
      });

      function resizeChart() {
        chart.resize();
      }

      function initChart() {
        chart = echarts.init(comRunChart.value);
        setOptionData(props.data);
      }

      function setOptionData(data) {
        chart.setOption(bedRateRunOptions(data));
      }

      return {
        comRunChart,
        resizeChart,
      };
    },
  });
</script>

<style lang="scss" scoped>
  #comRunChart {
    height: 100%;
    width: 100%;
  }
</style>
