import request from '../utils/request'

export function loginSystem(params) {
  return request({
    url: '/syUser/loginEmp',
    method: 'post',
    data:params
  })
}


export function logOutSystem(params) {
  return request({
    url: '/syUser/logOut',
    method: 'post',
    data:params
  })
}


export function queryGetDataForNoon(params) {
  return request({
    url: '/stuEmp/getDataForNoon',
    method: 'post',
    data: params
  })
}

export function queryLogInSendCode(params) {
  return request({
    url: '/syUser/logInSendCode',
    method: 'post',
    data: params
  })
}

export function queryGetDataSummary(params) {
  return request({
    url: '/stuEmp/getDataSummary',
    method: 'post',
    data: params
  })
}

export function queryGetSignStu(params) {
  return request({
    url: '/stuEmp/getSignStu',
    method: 'post',
    data: params
  })
}

export function queryGetDataForNoonDetail(params) {
  return request({
    url: '/stuEmp/getDataForNoonDetail',
    method: 'post',
    data: params
  })
}

export function querySyFaceLogSelectPage(params) {
  return request({
    url: '/syFaceLog/selectPage',
    method: 'post',
    data: params
  })
}

export function querySyDayDishGetEatRecords(params) {
  return request({
    url: '/syDayDish/getEatRecordsPage',
    method: 'post',
    data: params
  })
}

export function queryBusinessUserSelectPage(params) {
  return request({
    url: '/businessUser/selectPage',
    method: 'post',
    data: params
  })
}

export function querySyPerformanceDetail(params) {
  return request({
    url: '/syPerformanceDetail/detail',
    method: 'post',
    data: params
  })
}

export function queryGetSignStuDetail(params) {
  return request({
    url: '/stuEmp/getSignStuDetail',
    method: 'post',
    data: params
  })
}

export function querySyPerformanceUpdate(params) {
  return request({
    url: '/syPerformanceDetail/update',
    method: 'post',
    data: params
  })
}


export function querySyPerformanceAdd(params) {
  return request({
    url: '/syPerformanceDetail/add',
    method: 'post',
    data: params
  })
}

export function querySyUserUpdateFace(params) {
  return request({
    url: '/syUser/updateFace',
    method: 'post',
    data: params
  })
}

export function querySyUserUpdate(params) {
  return request({
    url: '/stuDetail/addOrUpdate',
    method: 'post',
    data: params
  })
}

export function querySelectUserById(params) {
  return request({
    url: '/syUser/selectUserById?id=' + params.id,
    method: 'post',
    data: params
  })
}

export function queryProductList(params) {
  return request({
    url: '/product/selectByPage',
    method: 'post',
    data: params
  })
}

export function queryProductExchange(params) {
  return request({
    url: '/product/exchange',
    method: 'post',
    data: params
  })
}

export function queryScoresGetDetailsPage(params) {
  return request({
    url: '/scores/getDetailsPage',
    method: 'post',
    data: params
  })
}

export function queryScoresAddRecordse(params) {
  return request({
    url: '/scores/addRecords',
    method: 'post',
    data: params
  })
}

export function queryGetEnum(params) {
  return request({
    url: '/enum/getEnum',
    method: 'get',
    params,
  })
}

export function querySelectOrgListByUser(params) {
  return request({
    url: '/syOrg/selectOrgListByUser',
    method: 'post',
    data: params
  })
}

export function queryBusinessUserData(params) {
  return request({
    url: '/businessUser/businessData',
    method: 'post',
    data: params
  })
}



















