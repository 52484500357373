import _, { forIn } from 'lodash-es';

export const studentRunOptions = (data) => {
  const x_data = [];
  const y_data = [];
  forIn(data, (value, key) => {
    x_data.push(value.stuNo)
    y_data.push(value.businessName)
  })
  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '2%',
      right: '4%',
      bottom: '12%',
      top: '4%',
      containLabel: true
    },
    color: ['#063050', '#FFEB3B', '#9C27B0', '#E91E63'],
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01]
    },
    yAxis: {
      type: 'category',
      data: y_data
    },
    series: [
      {
        name: '人数',
        type: 'bar',
        data: x_data
      }
    ]
  }
};
