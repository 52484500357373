<template>
  <div class="page-container">
    <div class="page-header" v-if="state.modeType == ModeType.ReturnHospital">
      <van-tabs v-model:active="active" type="card" @click-tab="handleClickTab">
        <van-tab :name="2" title="未回院"></van-tab>
        <van-tab :name="1" title="已回院"></van-tab>
      </van-tabs>
    </div>
    <div class="page-header" v-if="state.modeType == ModeType.Lunch">
      <van-tabs v-model:active="active" type="card" @click-tab="handleClickTab">
        <van-tab :name="2" title="未就餐"></van-tab>
        <van-tab :name="1" title="已就餐"></van-tab>
      </van-tabs>
    </div>
    <div class="page-header" v-if="state.modeType == ModeType.NoonBreak">
      <van-tabs v-model:active="active" type="card" @click-tab="handleClickTab">
        <van-tab :name="2" title="未进房"></van-tab>
        <van-tab :name="1" title="已进房"></van-tab>
      </van-tabs>
    </div>
    <div class="page-header" v-if="state.modeType == ModeType.LeavingHospital">
      <van-tabs v-model:active="active" type="card" @click-tab="handleClickTab">
        <van-tab :name="2" title="未离院"></van-tab>
        <van-tab :name="1" title="已离院"></van-tab>
      </van-tabs>
    </div>
    <div class="page-content">
      <div class="content-list">
        <div
          class="content-item"
          v-for="(item, index) in state.list"
          :key="index"
          @click="handleGoDetail(item)"
        >
          <div class="item-header">
            <van-image width="80" height="80" :src="getImg(item.faceImg)" fit="contain">
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
              <template v-slot:error>
                <van-image width="80" height="80" :src="DefaultAvatar" />
              </template>
            </van-image>
            <div style="margin-left: 12px; flex: 1">
              <div class="item-header-info">
                <div class="item-header-info-name">
                  {{ item.realName }} <span>{{ SexText[item.sex] }}</span>
                </div>
                <div class="item-header-info-status">
                  {{ HospitalStatusText[item.status] }}
                </div>
              </div>
              <div class="item-header-info">
                <span class="item-header-info-other" style="color: #113050;font-size: 14px;font-weight: 500;">{{
                  item.businessName
                }}</span>
                <span class="item-header-info-other"
                  >{{ getDay(item.checkInDate) }}~{{ getDay(item.expireDate) }}</span
                >
              </div>
            </div>
          </div>
          <div class="item-des" v-if="active == 1">
            <span style="background: #5470c6"></span> {{modeTypeDesText}}：{{item.faceTime ?? '--'}}
          </div>
        </div>
        <EmptyData style="margin-top: 100px" v-if="!state.list.length"/>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ModeType, HospitalStatusText, SexText } from "@/utils/const";
import { queryGetDataForNoonDetail } from "@/api/user";
import DefaultAvatar from '@/assets/avatar.jpg';
import EmptyData from '@/components/EmptyData.vue';
import { getImg } from '@/utils/methods';
import dayjs from "dayjs";
export default defineComponent({
  name: "MonitorStudents",

  components: {
    EmptyData
  },

  setup() {
    const route = useRoute();

    const router = useRouter();

    const active = ref(2);

    const state = reactive({
      list: [],
      modeType: route?.query?.moduleType,
      timeType: route?.query?.timeType,
    });

    const modeTypeDesText = computed(() => {
      let modeDesText = ''
      switch (Number(state.modeType)) {
        case ModeType.ReturnHospital:
          modeDesText = '已回院'
          break;
        case ModeType.Lunch:
          modeDesText = '已就餐'
          break;
        case ModeType.NoonBreak:
          modeDesText = '已进房'
          break;
        case ModeType.LeavingHospital:
          modeDesText = '已离院'
          break;
      
        default:
          break;
      }

      return modeDesText
    })

    onMounted(() => {
      queryGetDataForNoonDetailData();
    });



    const queryGetDataForNoonDetailData = async () => {
      state.list = [];
      try {
        const result = await queryGetDataForNoonDetail({
          status: active.value,
          time: state.timeType,
          type: state.modeType,
          day: dayjs().format("YYYY-MM-DD"),
          oneSelf: route?.query?.oneSelf,
          permissionOrg: route?.query?.permissionOrg
        });
        console.log("result:", result);
        if (result.code === "00") {
          state.list = result.data ?? [];
        } else {
          state.list = [];
        }
      } catch (error) {
        console.log("error:>>>", error);
        state.list = [];
      }
    };

    const handleClickTab = () => {
      queryGetDataForNoonDetailData();
    };

    const handleGoDetail = (row) => {
      console.log("row:", row)
      router.push({
        path: "/studentsInfo",
        query: {
          userId: row.id,
        },
      });
    };

    const getDay = (day) => {
      return dayjs(day).format('YYYY-MM-DD')
    }

    return {
      active,
      state,
      ModeType,
      handleGoDetail,
      handleClickTab,
      getImg,
      HospitalStatusText,
      SexText,
      getDay,
      modeTypeDesText,
      DefaultAvatar
    };
  },
});
</script>

<style lang="scss" scoped>
.page-container {
  background: #e9f1f8;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
}

.page-header {
  padding: 12px;
  margin: 0 68px;
}

.content-item {
  background: #fff;
  margin: 12px 8px;
  border-radius: 4px;
  padding: 12px;
  text-align: left;

  .item-header {
    display: flex;

    .item-header-info {
      display: flex;
      justify-content: space-between;
      padding: 4px 0;

      .item-header-info-name {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        font-weight: 600;

        span {
          font-size: 16px;
          font-weight: 500;
          color: burlywood;
        }
      }

      .item-header-info-status {
        color: #fff;
        font-size: 14px;
        background: #113050;
        padding: 4px 12px;
        border-radius: 24px;
      }

      .item-header-info-other {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        display: flex;
        align-items: center;
        height: 28px;

        p {
          color: #ff3638;
        }
      }
    }
  }

  .item-des {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 14px;

    span {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #ee6666;
      margin-right: 4px;
      display: inline-block;
    }
  }
}
</style>