<template>
    <router-view />
</template>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  touch-action: none; /* 禁止移动设备上的默认缩放手势 */
  overflow: hidden; /* 防止缩放后的内容溢出容器 */
  min-scale: 1; /* 最小缩放比例为1 */
  max-scale: 1; /* 最大缩放比例为1 */
}

body {
  
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
