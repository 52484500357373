import dayjs from 'dayjs';
import _ from 'lodash-es';
import { defaultViewData } from './filterDefaultViewData';
import { DateTypeEnum } from '@/config/dateConfig';

export const feeUtils = {
  ymdFormat: 'YYYY-MM-DD',
  startFormat: 'YYYY-MM-DD 00:00:00',
  endFormat: 'YYYY-MM-DD 23:59:59',
  wrapDayjs(date) {
    let dateObj = date;

    if (!dayjs.isDayjs(date)) {
      dateObj = dayjs(dateObj);
    }
    return dateObj;
  },
  formatToYMD(date) {
    if (date === defaultViewData || !date) {
      return date;
    }

    const dateObj = this.wrapDayjs(date);
    return dateObj.format(this.ymdFormat);
  },
  formatStartTime(date) {
    if (!date) {
      return null;
    }

    const dateObj = this.wrapDayjs(date);

    return dateObj.format(this.startFormat);
  },
  formatEndTime(date) {
    if (!date) {
      return null;
    }

    const dateObj = this.wrapDayjs(date);

    return dateObj.format(this.endFormat);
  },
  // 最近一个月
  getLastMonth() {
    return [dayjs().subtract(1, 'month').format(this.startFormat), dayjs().format(this.endFormat)];
  },
  getYMD(date) {
    return dayjs(date).format(this.ymdFormat);
  },
  getCycleDateByQuickType(query) {
    const { dateType } = query;
    if (dateType === DateTypeEnum.thisMonth) {
      const current = dayjs();
      const range = this.getApplyCycleByYM(current);
      return {
        dateType,
        beginDate: this.getYMD(range[0]),
        endDate: this.getYMD(range[1]),
      };
    } else if (dateType === DateTypeEnum.lastMonth) {
      const prev = dayjs().subtract(1, 'month');
      const range = this.getApplyCycleByYM(prev);
      return {
        dateType,
        beginDate: this.getYMD(range[0]),
        endDate: this.getYMD(range[1]),
      };
    }

    return query;
  },
  getApplyCycleByYM(date) {
    const _date = dayjs.isDayjs(date) ? date : dayjs(new Date(date));
    const startDay = 26;
    const endDay = 25;
    const lastStartDay = 1;
    const lastEndDay = 31;

    const year = _date.year();
    const month = _date.month();

    function getPrevMonth() {
      return _date.subtract(1, 'month').month();
    }

    if (month > 0 && month < 11) {
      const prevMonth = getPrevMonth();
      // 中间月份
      const start = dayjs().year(year).month(prevMonth).date(startDay).format(this.startFormat);
      const end = dayjs().year(year).month(month).date(endDay).format(this.endFormat);
      return [start, end];
    } else if (month === 11) {
      const prevMonth = getPrevMonth();
      // 12月
      const start = dayjs().year(year).month(prevMonth).date(startDay).format(this.startFormat);
      const end = dayjs().year(year).month(month).date(lastEndDay).format(this.endFormat);
      return [start, end];
    } else {
      // 1月
      const start = dayjs().year(year).month(month).date(lastStartDay).format(this.startFormat);
      const end = dayjs().year(year).month(month).date(endDay).format(this.endFormat);
      return [start, end];
    }
  },
  // 报销时间周期
  getApplyDateCycle() {
    return this.getApplyCycleByYM(dayjs());
  },
  // 主要用于过滤搜索 query 参数中的空值
  filterEmpty(obj, extraCondition) {
    const result = {};
    _.each(obj, function (value, key) {
      let condition = !_.isUndefined(value);

      if (_.isFunction(extraCondition)) {
        condition = condition && extraCondition(value, key);
      }

      if (condition) {
        result[key] = value;
      }
    });

    return result;
  },
};
