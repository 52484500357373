import _, { forIn } from 'lodash-es';

export const typeDistributionPie = (data) => {
  const _data = [];
  console.log(data)
  forIn(data, (value, key) => {
    if(key === 'newSignMoney') {
      _data.push({
        name: '新签',
        label: data.newSignNo,
        value: value
      })
    } else if(key === 'renewSignMoney') {
      _data.push({
        name: '续签',
        label: data.renewSignNo,
        value: value
      })
    }
   
  })
  return {
    color: ['#063050', '#FFEB3B', '#9C27B0', '#E91E63'],
    legend: {
      left: 'center',
      bottom: '5%',
    },
    tooltip: {
      trigger: 'item',
      formatter: function (res) {
        const { data,percent } = res
        console.log(res)
        return `${data.name}:  ${data.label}人</br>共${data.value}元</br>占比${percent}%`
      }
    },
    series: [
      {
        type: 'pie',
        radius: '50%',
        data: _data,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  }
};
