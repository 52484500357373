import _, { forIn } from 'lodash-es';

export const comparisonnRunOptions = (data) => {
  const _data = [];
  let total = 0;
  forIn(data, (value, key) => {
    total = total + Number(value)
    _data.push( { value, name: `${key}(${value})`, selected: false })
  })
  return {
    legend: {
      bottom: '12%',
      left: 'center'
    },
    series: [
      {
        type: 'pie',
        center: ['50%','36%'],
        radius: ['35%', '68%'],
        avoidLabelOverlap: false,
        padAngle: 5,
        itemStyle: {
          borderRadius: 10
        },
        label: {
          show: true,
          position: 'center',
          fontSize: 28,
          fontWeight: 'bold',
          formatter: function(params) {
            return total + '种';
          }
        },
        labelLine: {
          show: false
        },
        data: _data
      }
    ]
  }
};