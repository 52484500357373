import _, { forIn } from 'lodash-es';

export const bedRateRunOptions = (data) => {
  console.log('==++-==',data)
  const x_data = [];
  const y_data = [];
  forIn(data, (value, key) => {
    if(key === 'allBoyRate') {
      x_data.push('全托-男')
      y_data.push((value*100).toFixed(2))
    } else if(key === 'allGirlRate') {
      x_data.push('全托-女')
      y_data.push((value*100).toFixed(2))
    } else if(key === 'noonBoyRate') {
      x_data.push('午托-男')
      y_data.push((value*100).toFixed(2))
    } else if(key === 'noonGirlRate') {
      x_data.push('午托-女')
      y_data.push((value*100).toFixed(2))
    }
    
  })

  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '12%',
      top: '6%',
      containLabel: true
    },
    color: ['#063050'],
    xAxis: [
      {
        type: 'category',
        data: x_data,
        axisTick: {
          alignWithLabel: true
        }
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: '使用率(%)',
        type: 'bar',
        barWidth: '60%',
        data: y_data
      }
    ]
  }
};
