import dayjs from 'dayjs';

export const QUICK_KEY = {
  today: 'today',
  yesterday: 'yesterday',
  currentWeek: 'currentWeek',
  currentMonth: 'currentMonth',
  currentYear: 'currentYear',
  prevWeek: 'prevWeek',
  prevMonth: 'prevMonth',
  custom: 'custom',
};

export const QUICK_TEXT = {
  [QUICK_KEY.custom]: '自定义',
  [QUICK_KEY.today]: '今日',
  [QUICK_KEY.yesterday]: '昨日',
  [QUICK_KEY.currentWeek]: '本周',
  [QUICK_KEY.currentMonth]: '本月',
  [QUICK_KEY.currentYear]: '全年',
  [QUICK_KEY.prevWeek]: '上周',
  [QUICK_KEY.prevMonth]: '上月',
};

export const formatString = 'YYYY-MM-DD';

export function today() {
  const start = dayjs().format(formatString);
  // const end = dayjs().add(1, 'day').format(formatString);

  return [start, start];
}

export function yesterday() {
  const start = dayjs().subtract(1, 'day').format(formatString);
  // const end = dayjs().format(formatString);
  const end = dayjs().subtract(1, 'day').format(formatString);
  return [start, end];
}

export function currentWeek() {
  const start = dayjs()
    .subtract(dayjs().day() ? dayjs().day() - 1 : 6, 'day')
    .format(formatString);
  const end = dayjs().format(formatString);

  return [start, end];
}

export function currentMonth() {
  const start = dayjs().startOf('month').format(formatString);
  const end = dayjs().format(formatString);
  return [start, end];
}

export function currentYear() {
  const start = dayjs().startOf('year').format(formatString);
  const end = dayjs().format(formatString);
  return [start, end];
}

export function prevWeek() {
  const start = dayjs().add(-1, 'week').startOf('week').add(1, 'day').format(formatString);
  const end = dayjs().add(-1, 'week').endOf('week').add(1, 'day').format(formatString);
  return [start, end];
}

export function prevMonth() {
  const start = dayjs().add(-1, 'month').startOf('month').format(formatString);
  const end = dayjs().add(-1, 'month').endOf('month').format(formatString);
  return [start, end];
}

export function getDateRangeByType(type, formData = {}) {
  if (type === QUICK_KEY.today) {
    return today();
  } else if (type === QUICK_KEY.yesterday) {
    return yesterday();
  } else if (type === QUICK_KEY.currentWeek) {
    return currentWeek();
  } else if (type === QUICK_KEY.currentMonth) {
    return currentMonth();
  } else if (type === QUICK_KEY.currentYear) {
    return currentYear();
  } else if (type === QUICK_KEY.prevWeek) {
    return prevWeek();
  } else if (type === QUICK_KEY.prevMonth) {
    return prevMonth();
  } else if (type === QUICK_KEY.custom) {
    const customData = localStorage.getItem('customData');
    if (customData) {
      return customData.split(',');
    } else if (formData?.dateRange) {
      return formData.dateRange;
    } else {
      return [];
    }
  } else {
    return formData?.dateRange ?? [];
  }
}
