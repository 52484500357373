<template>
  <div class="popup-header">
    <h2 class="popup-header-title">
      <slot></slot>
    </h2>
  </div>
</template>

<style scoped lang="scss">
  .popup-header {
    padding: 16px;
    border-bottom: 1px solid #e5e6eb;
  }

  .popup-header-title {
    margin: 0;
    color: #1d2129;
    font-family: 'PingFang SC';
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }
</style>
