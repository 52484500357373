<template>
  <div>
    <div>
        <van-image fit="contain" style="width: 160px; height: 160px; margin-top: 88px;" :src="require('@/assets/logo_daoshi.jpg')" />  
        <div style="margin-top: 44px;margin-bottom: 44px">
          登录              
        </div>
    </div>
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field
          v-model="state.telephone"
          name="手机号"
          label="手机号"
          placeholder="手机号"
          type="tel"
          :rules="[{ required: true, message: '请填写手机号' }]"
        />
        <van-field
          v-model="state.captcha"
          center
          clearable
          label="图形验证码"
          :rules="[{ required: true, message: '请填写图形验证码' }]"
          placeholder="请输入图形验证码"
        >
          <template #extra>
            <img @click="updateImage" style="width: 80px; height: 40px;" :src="state.imageContent" />
          </template>
        </van-field>
        <van-field
          v-model="state.code"
          center
          clearable
          label="短信验证码"
          :rules="[{ required: true, message: '请填写验证码' }]"
          placeholder="请输入短信验证码"
        >
          <template #button>
            <van-button v-if="state.time==60" size="small" @click="sendCode" style="background-color: #28B079;color: #ffffff;">发送验证码</van-button>
            <van-button v-else size="small" type="primary">{{state.time}}s</van-button>
          </template>
        </van-field>
      </van-cell-group>
      <div style="margin: 44px 16px">
        <van-button class="login-btn-sumit" round block native-type="submit">
          提交
        </van-button>

        <view style="margin-top: 16px" class="login-other-type" @click="loginByPhone">
          手机号快速登录
        </view>
      </div>
    </van-form>
    <van-loading v-if="state.isSendLoading" size="24px">验证码发送中...</van-loading>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { loginSystem, queryLogInSendCode } from '@/api/user'
import { useRouter } from "vue-router";
import { showNotify } from 'vant';

export default defineComponent({
  components: {},
  setup() {
    const state = reactive({
        telephone: '',
        code: '',
        time: 60,
        isSendLoading: false,
        captcha: '',
        imageContent: process.env.VUE_APP_BASE_API + '/syUser/getimg?time' + new Date().valueOf()
    });

    const router = useRouter();

    onMounted(async () => {});

    const loginByPhone = () => {
   
      window.wx.miniProgram.navigateTo({
        url: '/pages/loginPhone/loginPhone'
      })
    }

    const sendCode = async () => {
      console.log('sendCode');

				if(!state.telephone) {
          showNotify({ type: 'warning', message: '请填写登录手机号', duration: 2000 })
					return
				}
        state.isSendLoading = true;
				try{
					const result = await queryLogInSendCode({
						telephone: state.telephone,
            captcha: state.captcha
					})
					
					if(result.code == '00') {
            state.isSendLoading = false;
            showNotify({ type: 'success', message: '验证码已发送', duration: 2000 })
						state.time = 60;
						let timer = setInterval(() => {
							if (state.time <= 0) {
								clearInterval(timer);
								timer = null;
								state.time = 60;
								return;
							}
							state.time--;
						}, 1000)
					} else {
						state.isSendLoading = false;
            showNotify({ type: 'warning', message: result.message, duration: 2000 })
					}
					
					
				}catch(e){
					//TODO handle the exception
					console.log(e)
				}
    }

    const updateImage = () => {
      state.imageContent = process.env.VUE_APP_BASE_API + '/syUser/getimg?time' + new Date().valueOf()
    }

    
    

    const onSubmit = async (values) => {
        console.log('submit', values);
        try {
            const result = await loginSystem({
                logInType: 2,
                code: state.code,
			          telephone: state.telephone,
            })

            if (result.code == '00') {
                
                localStorage.setItem('userInfo', JSON.stringify(result.data));
                showNotify({ type: 'success', message: '登录成功' , duration: 2000 })

                setTimeout(() => {
                  router.back();
                }, 1000);
            } else {
                showNotify({ type: 'warning', message: result.message, duration: 2000 })
            }

        } catch (error) {
            console.log('error', error);
        }
    }

    return {
      state,
      onSubmit,
      sendCode,
      loginByPhone,
      updateImage
    };
  },
});
</script>

<style scoped>

.login-btn-sumit {
  height: 40px;
  background: #28B079;
  border-radius: 20px;
  color: #ffffff;
  width: calc(100% - 32px);
  margin: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}

.login-other-type {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #28B079;
  margin-top: 22px;
  cursor: pointer;
}
</style>
